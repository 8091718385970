import React, { useState } from "react";
import { getTrackingHistory } from "../services/tracking.service";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Loader from "./Loader";

const initialValues = {
  airwayBills: "",
};
const checkoutSchema = yup.object().shape({
  airwayBills: yup.string().required("required"),
});
const Tracking = () => {
  const [validBills, setValidBills] = useState();
  const [loader, setLoader] = useState(false);

  // const handleChange = (event, airwayBillNo) => {
  //   setLoader(true);

  //   if (airwayBillNo !== null) {
  //     let bil = validBills.find((x) => x.airwayBillNo === airwayBillNo);
  //     setSelectedBill(bil);
  //     setSteps(
  //       bil?.tracking?.map((x) => ({
  //         label:
  //           x.statusName +
  //           (x.subStatusName ? " (" + x.subStatusName + ")" : ""),
  //         description: x.statusDate + " " + x.statusTime,
  //       }))
  //     );
  //   }
  //   setLoader(false);
  // };

  const HandleSumbit = (awbNumbers) => {
    setLoader(true);
    let awbNums = awbNumbers.airwayBills
      .replaceAll(" ", ",")
      .replaceAll("\n", ",")
      .replaceAll(",,", ",")
      .replaceAll(",,,", ",");
    awbNumbers.airwayBills = awbNums;
    getTrackingHistory(awbNums).then((res) => {
      debugger;
      if (res.success) {
        // setValidBillNos(res.data.validBillsNo);
        setValidBills(res.data.validBills);
        // setSelectedBill(undefined);
        // setSteps([]);
      }
      setLoader(false);
    });
  };
  return (
    <>
      {" "}
      <section className="container py-10 md:py-16">
        <Formik
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          enableReinitialize={true}
          onSubmit={(values) => HandleSumbit(values)}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="grid grid-cols-4 bg-base-200 rounded-xl shadow-xl p-5">
                <div className="col-span-3 text-center">
                  <label className="form-control w-full">
                    <input
                      type="text"
                      placeholder="Enter your tracking number"
                      className="input input-lg input-primary input-bordered"
                      name="airwayBills"
                      value={values.airwayBills}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyUp={() => {
                        setFieldValue(
                          "airwayBills",
                          values.airwayBills
                            .replaceAll(" ", ",")
                            .replaceAll("\n", ",")
                            .replaceAll(",,", ",")
                            .replaceAll(",,,", ",")
                        );
                      }}
                    />
                    <div className="label">
                      <span className="label-text-alt">
                        multiple shipments can be tracked as [trackingNo1,
                        trackingNo2, ......, n]
                      </span>
                    </div>
                  </label>
                </div>
                <div>
                  {loader ? (
                    <Loader />
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-lg btn-warning btn-wide text-2xl mx-2"
                    >
                      Start Tracking
                    </button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
      <section className="container py-10 md:py-16 min-h-full">
        {loader ? (
          <Loader />
        ) : validBills ? (
          <div className="grid bg-base-200 rounded-xl shadow-xl p-5">
            {validBills.length === 0 ? (
              <div className="hero bg-base-200 min-s-screen">
                <div className="hero-content text-center">
                  <div className="max-w-md">
                    <h1 className="text-5xl font-bold">
                      Tracking Number not found!
                    </h1>
                    <p className="py-6">
                      We could not find the provided tracking number, Please
                      check again later.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div role="tablist" className="tabs tabs-boxed tabs-xs">
                {validBills.map((bill, index) => (
                  <>
                    <input
                      type="radio"
                      name={"awb_tabs"}
                      role="tab"
                      className="tab min-h-20"
                      aria-label={bill.airwayBillNo}
                      defaultChecked={index === 0}
                      key={index}
                      value={bill.airwayBillNo}
                    />
                    <div
                      role="tabpanel"
                      className="tab-content bg-base-100 border-base-300 rounded-box p-6  min-h-96"
                      // key={"content_" + index}
                    >
                      <div className="flex flex-row">
                        <div className="basis-1/2 min-w-96">
                          <ul className="timeline timeline-vertical">
                            {bill?.tracking?.map((x, index) => (
                              <li>
                                <div className="timeline-start">
                                  {" "}
                                  {x.statusDate + " " + x.statusTime}
                                </div>
                                <div className="timeline-middle">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                                <div className="timeline-end timeline-box">
                                  {x.statusName +
                                    (x.subStatusName
                                      ? " (" + x.subStatusName + ")"
                                      : "")}
                                </div>
                                <hr />
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="divider lg:divider-horizontal"></div>
                        <div className="basis-1/2  mx-6">
                          <div className="grid grid-cols-2 gap-8">
                            <div>
                              <h2 className="font-bold">AWB</h2>
                              <p>{bill.airwayBillNo}</p>
                            </div>
                            <div>
                              <h2 className="font-bold">Last Updated</h2>
                              <p>{bill.statusDate + " " + bill.statusTime}</p>
                            </div>
                            <div>
                              <h2 className="font-bold">Shipment Status</h2>
                              <p>{bill.statusName}</p>
                            </div>
                            <div>
                              <h2 className="font-bold">Service Code</h2>
                              <p>{bill.serviceCodeName}</p>
                            </div>

                            <div className="col-span-2">
                              <h2 className="font-bold">Description</h2>
                              <p>{bill.packageDescription}</p>
                            </div>
                            {/* <div className="col-span-2">
                              <h2 className="font-bold">
                                Special Instructions
                              </h2>
                              <p>bill.Status</p>
                            </div> */}
                            <div className="divider"></div>

                            <div className="col-span-2">
                              <h2 className="font-bold">Delivery Address</h2>
                              <p>{bill.addressLine1}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </section>
    </>
  );
};

export default Tracking;
