import React from "react";
import logo from "../assets/ColoredLogoTricornerX.png";
import { NavLink } from "react-router-dom";
const THEMES = [
  "light",
  "dark",
  "cupcake",
  "bumblebee",
  "emerald",
  "corporate",
  "synthwave",
  "retro",
  "cyberpunk",
  "valentine",
  "halloween",
  "garden",
  "forest",
  "aqua",
  "lofi",
  "pastel",
  "fantasy",
  "wireframe",
  "black",
  "luxury",
  "dracula",
  "cmyk",
  "autumn",
  "business",
  "acid",
  "lemonade",
  "night",
  "coffee",
  "winter",
];

export default function Header() {
  const [theme, setTheme] = React.useState("light");

  React.useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    setTheme(theme);
  }, [theme]);

  // const handleThemeChange = (e) => {
  //   const val = e.target.getAttribute("data-set-theme");
  //   setTheme(val);
  // };

  return (
    <header className="bg-base-100 py-2 sticky top-0 z-50">
      <div className="container">
        <div className="navbar px-0">
          <div className="navbar-start">
            <div className="dropdown">
              <label tabIndex={0} className="btn btn-circle  lg:hidden mr-1">
                <i className="bi bi-list text-2xl"></i>
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content mt-1 w-52 menu menu-compact p-2 bg-base-200 shadow rounded-box"
              >
                <li>
                  {" "}
                  <NavLink
                    to="/track"
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "active" : ""
                    }
                  >
                    Track your Shipments
                  </NavLink>
                </li>
                <li>
                  {/* Navlink
                  <a href="#!" >Home</a> */}
                  <NavLink
                    to="/home"
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "active" : ""
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <a href="#!">About</a>
                </li>
                <li>
                  <div
                    className="dropdown dropdown-end"
                    onClick={() =>
                      window.open("https://app.tricornerx.com", "_blank")
                    }
                  >
                    Sign In
                  </div>
                </li>
              </ul>
            </div>
            <div className="normal-case text-2xl">
              <img
                src={logo}
                className="w-full h-50"
                alt="Tricorner X Delivery Services"
              />
              {/**/}
            </div>
          </div>
          <div className="navbar-end hidden lg:flex">
            <ul
              className="menu menu-horizontal p-0 font-medium"
              style={{ alignItems: "center" }}
            >
              <li>
                {/* <div>
                  <button
                    className="dropdown dropdown-end btn btn-warning sm:btn-wide"
                    onClick={() => {
                      window.open(
                        "https://app.tricornerx.com/tracker",
                        "_blank"
                      );
                    }}
                  >
                    Track your Shipments
                  </button>
                </div> */}
                <NavLink
                  to="/track"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  Track your Shipments
                </NavLink>
              </li>
              <li>
                {/* <a className="dropdown dropdown-end" href="#!">
                  Home
                </a> */}
                <NavLink
                  to="/home"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  Home
                </NavLink>
              </li>
              <li>
                <a className="dropdown dropdown-end" href="#!">
                  About
                </a>
              </li>
              <li>
                <div
                  className="dropdown dropdown-end"
                  onClick={() =>
                    window.open("https://app.tricornerx.com", "_blank")
                  }
                >
                  Sign In
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
