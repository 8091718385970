// MyService.js
import axios from "axios";
// const API_BASE_URL = "http://192.168.0.126:5214";
const API_BASE_URL = "https://api.tricornerx.com";

// const refreshToken = localStorage.getItem("refreshToken");
// const accessToken: () => localStorage.getItem("accessToken");
const BaseService = {
  // Example of a function to fetch data
  fetchData: async (endpoint, isSecure = true) => {
    try {
      let headers = {};
      let accessToken = localStorage.getItem("accessToken");
      const response = await axios.get(
        `${API_BASE_URL}/${endpoint}`,
        isSecure
          ? {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          : headers
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching data: " + JSON.stringify(error));
      throw error; // You may want to handle errors differently based on your needs
    }
  },

  // Example of a function to post data
  postData: async (endpoint, data, isSecure = true) => {
    try {
      let headers = {};
      let accessToken = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${API_BASE_URL}/${endpoint}`,
        data,
        isSecure
          ? {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          : headers
      );

      return response.data;
    } catch (error) {
      console.error("Error posting data: " + JSON.stringify(error));
      throw error;
    }
  },
};

export default BaseService;
