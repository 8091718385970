import React from "react";

export default function Services() {
  return (
    <section className="py-10 md:py-16">
      <div className="container">
        <div className="text-center">
          <h2 className="text-3xl sm:text-5xl font-bold mb-4">What We Do</h2>
          <p className="text-lg sm:text-2xl mb-6 md:mb-14">
            TricornerX Offers wide in time facilities of services with
            proficiency and responsibility that includes transportation,
            e-commerce courier solutions, logistics both services on domestic
            and internation destinations.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 lg:gap-8 xl:gap-10">
          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              {/* <i className="bi bi-search text-4xl"></i> */}
              <h2 className="card-title">Vision</h2>
              <p>
                Through logistics connect and build relations in different
                people and communities.
                {/* This is a wider card with <br className="hidden xl:inline" />
                supporting text below as a <br className="hidden xl:inline" />{" "}
                natural content. */}
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              {/* <i className="bi bi-chat-left-dots text-4xl"></i> */}
              <h2 className="card-title">Mission</h2>
              <p>
                To reimagine commerce in more innovative and easy ways that
                fulfilling the desire of mental peace by shipping commodities.
                We are building positive, respective and prosperous relations
                between humans through logistics
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              {/* <i className="bi bi-badge-ad text-4xl"></i> */}
              <h2 className="card-title">Who we are</h2>
              <p>
                We are Dubai based company and providing best logistics and
                courier services in UAE, GCC, and World Wide
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              {/* <i className="bi bi-card-checklist text-4xl"></i> */}
              <h2 className="card-title">Major Destinations</h2>
              <p>
                <ul className="list-disc text-left">
                  <li>UAE</li>
                  <li>Saudi Arabia</li>
                  <li>Oman</li>
                  <li>Qatar</li>
                  <li>Bahrain</li>
                  <li>Kuwait</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              {/* <i className="bi bi-wallet2 text-4xl"></i> */}
              <h2 className="card-title">Services we provide</h2>
              <p>
                <ul className="list-disc text-left">
                  <li>Express / B2C</li>
                  <li>Prepaid</li>
                  <li>Cash on Delivery (COD)</li>
                  <li>International Mile</li>
                  <li>Wareshousing</li>
                  <li>Fulfilment</li>
                  <li>Last Mile</li>
                  <li>Return</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="card bg-base-200 transform-gpu transition-all duration-300 hover:-translate-y-2 hover:shadow-lg">
            <div className="card-body items-center text-center gap-4">
              {/* <i className="bi bi-funnel text-4xl"></i> */}
              <h2 className="card-title">Reason to work with us</h2>
              <p>
                <ul className="list-disc text-left">
                  <li>
                    Team of young and enthusiastic with new ideas and innovation
                  </li>
                  <li>High deliver success rate </li>
                  <li>Best Lead Time</li>
                  <li>Tracking</li>
                  <li>Competitive Pricing</li>
                  <li>Quick Response</li>
                  <li>Direct Response to Customers</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
