import React from "react";
import Home from "../pages/home";
import ErrorPage from "../components/error-pages/error-page";
import Tracker from "../pages/tracker";
import App from "../App";

export default [
  {
    path: "/",
    element: <App />,

    children: [
      { index: true, path: "/", element: <Home /> },
      { index: true, path: "/home", element: <Home /> },
      { path: "/track", element: <Tracker /> },
    ],
    errorElement: <ErrorPage />,
  },
];
